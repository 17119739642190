import { inflate, deflate } from "pako";
import { constants } from "../constants/constants";
import { isNullish, objFromZlib, objToZlib } from "../constants/helpers";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
	data() {
		return {
			localStorageWatchers: new Set(),
		};
	},
	computed: {
		...mapGetters(["getRemoveFooters"]),
	},
	methods: {
		$syncAndAssign(key, ...vals) {
			for (const val of vals) {
				this.$syncWithStorage(val, key);
				const tmp = this.$getFromStorage(val, undefined, key);
				if (!isNullish(tmp)) {
					this[val] = tmp;
				}
			}
		},

		$setToStorage(route, key, value) {
			const tempItem = this.$getFromStorage(null, true) ?? {};
			if (Array.isArray(route)) {
				_.set(tempItem, [...route, key], value);
			} else {
				_.set(tempItem, [route, key], value);
			}

			sessionStorage.setItem(
				constants.footerLocalStorageKey,
				objToZlib(tempItem)
			);
		},

		$getFromStorage(key, object = false, path = this.$route.name) {
			const data = sessionStorage.getItem(constants.footerLocalStorageKey);
			//Early escape if data not available
			if (isNullish(data)) {
				return null;
			}

			//Convert to ArrayBuffer
			let parsedData = objFromZlib(data);
			if (!object) {
				if (Array.isArray(path)) {
					parsedData = _.get(parsedData, [...path]);
				} else {
					parsedData = _.get(parsedData, path);
				}
			}
			if (key) {
				parsedData = parsedData?.[key];
			}

			if (isNullish(parsedData)) {
				return null;
			}

			try {
				//Parse data here to check for parse errors.
				return parsedData;
			} catch (error) {
				//And fuck off if something went wrong.
				console.error("Error parsing JSON data:", error);
				return null;
			}
		},

		$syncWithStorage(propName, routeName = this.$route.name) {
			const arr = Array.isArray(propName) ? propName : [propName];
			for (const propName of arr) {
				this.localStorageWatchers.add({ propName, routeName });
			}
		},

		$beforeunloadHandler(e) {
			if (this.getRemoveFooters) return;
			this.localStorageWatchers.forEach(async (e) => {
				await this.$setToStorage(e.routeName, e.propName, this[e.propName]);
			});
		},
	},

	created() {
		window.addEventListener("beforeunload", this.$beforeunloadHandler);
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.$beforeunloadHandler);
		if (this.getRemoveFooters) return;
		this.localStorageWatchers.forEach(async (e) => {
			await this.$setToStorage(e.routeName, e.propName, this[e.propName]);
		});
	},
};
