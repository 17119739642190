<template>
	<v-menu
		:close-on-content-click="false"
		v-model="menuOpen"
		bottom
		left
		offset-y
		origin="top right"
		transition="scale-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				class="mb-0"
				elevation="1"
				:fab="isMobile || fab"
				:small="isMobile || fab"
				color="primary kajot-text--text text-center"
				v-bind="attrs"
				v-on="on">
				<span>{{ isMobile || fab ? currentCasino : currentCasinoName }}</span>
				<v-icon
					v-if="!isMobile && !fab"
					right
					class="ml-2 kajot_dark_dark_gray--text">
					{{ menuOpen ? "mdi-menu-up" : "mdi-menu-down" }}
				</v-icon>
			</v-btn>
		</template>

		<v-list min-width="250">
			<v-list-item>
				<v-text-field
					dense
					v-model="search"
					autofocus
					hide-details
					label="Search Casinos"></v-text-field>
				<v-btn
					text
					fab
					small
					:color="sortBy == 'website' ? 'primary' : undefined"
					@click="() => changeSort('website')">
					<v-icon>
						{{
							this.sortBy === "website" && this.sortOrder === "desc"
								? `mdi-sort-alphabetical-descending`
								: `mdi-sort-alphabetical-ascending`
						}}
					</v-icon>
				</v-btn>
				<v-btn
					text
					fab
					small
					:color="sortBy == 'casino_id' ? 'primary' : undefined"
					@click="() => changeSort('casino_id')">
					<v-icon>
						{{
							this.sortBy === "casino_id" && this.sortOrder === "desc"
								? `mdi-sort-numeric-descending`
								: `mdi-sort-numeric-ascending`
						}}
					</v-icon>
				</v-btn>
			</v-list-item>
			<v-divider></v-divider>
			<v-virtual-scroll
				:height="scrollerHeight"
				:item-height="scrollerItemHeight"
				:items="filteredCasinos"
				v-slot="{ item }">
				<v-list-item
					@click="handleClick(item)"
					:key="item.casino_id">
					<v-list-item-content>
						<v-list-item-title>
							<span>{{ item.website }}</span>
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-chip>
							<span class="font-weight-thin">
								CID:
								<strong>{{ item.casino_id }}</strong>
							</span>
						</v-chip>
					</v-list-item-action>
				</v-list-item>
			</v-virtual-scroll>
		</v-list>
	</v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import localstorage from "../../../mixins/localstorage";

export default {
	mixins: [localstorage],
	data() {
		return {
			sortBy_: "website",
			sortOrder: "asc",
			menuOpen: false,
			search: "",
			scrollerItemHeight: 50,
			maxDisplayedItems: 5,
		};
	},
	props: {
		fab: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		sortBy: {
			get() {
				return this.sortBy_;
			},
			set(val) {
				this.sortBy_ = val;
			},
		},
		...mapGetters(["allowedCasinos", "currentCasinoName", "currentCasino"]),
		filteredCasinos() {
			return (
				this.allowedCasinos
					?.filter(
						(el) =>
							el.website?.toLowerCase().search(this.search.toLowerCase()) >
								-1 || el.casino_id?.toString()?.search(this.search) > -1
					)
					.sort((a, b) =>
						typeof a[this.sortBy] === "string"
							? this.sortOrder === "asc"
								? a[this.sortBy].localeCompare(b[this.sortBy])
								: b[this.sortBy].localeCompare(a[this.sortBy])
							: this.sortOrder === "asc"
							? a[this.sortBy] - b[this.sortBy]
							: b[this.sortBy] - a[this.sortBy]
					) ?? []
			);
		},
		scrollerHeight() {
			return Math.min(
				this.maxDisplayedItems * this.scrollerItemHeight + 10,
				this.filteredCasinos.length * this.scrollerItemHeight + 10
			);
		},
	},
	methods: {
		...mapActions(["setupCurrentCasino"]),
		changeSort(by) {
			if (this.sortBy === by) {
				this.toggleSortOrder();
			} else {
				this.sortOrder = "asc";
				this.sortBy = by;
			}
		},
		toggleSortOrder() {
			this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
		},
		handleClick(casino) {
			this.setupCurrentCasino(casino);
			this.menuOpen = false;
			if (this.$route.params.casino) {
				this.$router.push({
					name: this.$route.name,
					params: {
						...(this.$route?.params ?? {}),
						casino: this.currentCasino,
					},
				});
			}
		},
	},
	created() {
		this.$syncAndAssign("heading_global", "sortBy_");
		this.$syncAndAssign("heading_global", "sortOrder");
	},
};
</script>
