var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        bottom: "",
        left: "",
        "offset-y": "",
        origin: "top right",
        transition: "scale-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mb-0",
                      attrs: {
                        elevation: "1",
                        fab: _vm.isMobile || _vm.fab,
                        small: _vm.isMobile || _vm.fab,
                        color: "primary kajot-text--text text-center",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.isMobile || _vm.fab
                          ? _vm.currentCasino
                          : _vm.currentCasinoName
                      )
                    ),
                  ]),
                  !_vm.isMobile && !_vm.fab
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "ml-2 kajot_dark_dark_gray--text",
                          attrs: { right: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.menuOpen ? "mdi-menu-up" : "mdi-menu-down"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function ($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { "min-width": "250" } },
        [
          _c(
            "v-list-item",
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  autofocus: "",
                  "hide-details": "",
                  label: "Search Casinos",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    fab: "",
                    small: "",
                    color: _vm.sortBy == "website" ? "primary" : undefined,
                  },
                  on: { click: () => _vm.changeSort("website") },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.sortBy === "website" && this.sortOrder === "desc"
                            ? `mdi-sort-alphabetical-descending`
                            : `mdi-sort-alphabetical-ascending`
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    fab: "",
                    small: "",
                    color: _vm.sortBy == "casino_id" ? "primary" : undefined,
                  },
                  on: { click: () => _vm.changeSort("casino_id") },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.sortBy === "casino_id" &&
                            this.sortOrder === "desc"
                            ? `mdi-sort-numeric-descending`
                            : `mdi-sort-numeric-ascending`
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-virtual-scroll", {
            attrs: {
              height: _vm.scrollerHeight,
              "item-height": _vm.scrollerItemHeight,
              items: _vm.filteredCasinos,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-list-item",
                      {
                        key: item.casino_id,
                        on: {
                          click: function ($event) {
                            return _vm.handleClick(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _c("span", [_vm._v(_vm._s(item.website))]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-chip", [
                              _c("span", { staticClass: "font-weight-thin" }, [
                                _vm._v(" CID: "),
                                _c("strong", [_vm._v(_vm._s(item.casino_id))]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }